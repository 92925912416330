.form-control {
    background-color: rgba(1, 104, 253, 0.2);
    /* background-color: #ffffff; */
}

.formLogo {
    width: 20vw;
    height: 15vw;
    overflow: hidden;
}

.toast_div {
    position: fixed;
    top: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 15px 100px;
    margin: auto;
    color: white;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: bold;
}

.toast_color_success {
    background-color: #4aa254;
}

.toast_color_danger {
    background-color: #c74344;
}

@media (max-width: 760px) {
    .formLogo {
        display: none;
    }

    .form-label {
        font-size: 3.5vw;
    }

    .toast_div {
        font-size: 10px;
        padding: 10px 30px;
    }
}

@media (min-width: 1440px) {
    .formLogo {
        width: 20rem;
        height: 15rem;
        overflow: hidden;
    }
}
